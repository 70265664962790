import request from '@/utils/request'

const api = {
  user: '/admincp/order/log/index'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}
