<template>
  <div>
    <a-card title="历史操作" >
      <div v-for="item in list" :key="item.logId" :value="item.logId">
        <span style="margin-right:15px ">{{ item.createTime|dataFormat }}</span>
        <span>{{ item.msg }}</span>
      </div>
    </a-card>
    <a-card title="历史备注" >
      <div v-for="item in remarks" :key="item.logId" :value="item.logId">
        <span style="margin-right:15px ">{{ item.createTime|dataFormat }}</span>
        <span>{{ item.msg }}</span>
      </div>
    </a-card>
    <a-card title="备注" >
      <div :key="newRemarks">
        <span>{{ null==newRemarks?'无':newRemarks }}</span>
      </div>
    </a-card>
  </div>

</template>
<script>
import { formatterTime } from '@/utils/util'
import { FetchList } from '@/api/orderlog'

export default {
  filters: {
    dataFormat (msg) {
      return formatterTime(msg)
    }
  },
  data () {
    return {
      orderId: 0,
      newRemarks: '',
      info: {
        truename: undefined
      },
      remarks: [],
      list: []
    }
  },
  methods: {

    setOrderId (orderId, newRemarks) {
      this.orderId = orderId
      this.newRemarks = newRemarks
      this.getLog()
      this.getLogRecord()
    },
    getLog () {
      FetchList({ orderId: this.orderId, type: 1 })
        .then(res => {
          this.list = res.result.data
        })
    },
    getLogRecord () {
      FetchList({ orderId: this.orderId, type: 2 })
        .then(res => {
          this.remarks = res.result.data
        })
    },
    refreshNewRemarks (newRemarks) {
        this.newRemarks = newRemarks
    }
  }
}
</script>
